import styled from "styled-components";
// components
import Container from "./Container";
// assets
import heroBgImg from "../img/hero-bg.jpg";
import Navbar from "./Navbar";

const StyledInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
  }
`;

const StyledButton = styled.button`
  padding: 1em 2em;

  border: none;
  border-radius: 10px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: white;

  cursor: pointer;
`;

const StyledHeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;

  width: 100%;
  padding: 0 1em;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
    padding: 0;
  }
`;

const StyledHero = styled.section`
  position: relative;
  height: 90vh;
  width: 100%;

  color: white;
  background-image: url(${heroBgImg});
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <Navbar />
        <StyledHeroContent>
          <StyledInnerContent>
            {" "}
            <h1>
              Total <span>Solution</span> for <br /> Server & Storage System{" "}
            </h1>
            <p>
              Leading solution provider for enterprise server and network
              storage infrastructure in Indonesia
            </p>
            <a href="#featured">    <StyledButton>Get started</StyledButton></a>
        
          </StyledInnerContent>
        </StyledHeroContent>
      </Container>
    </StyledHero>
  );
};

export default Hero;
