import seagateImg from "./img/partners/seagate.jpg"
import ibmImg from "./img/partners/ibm.jpg"
import kasperskyImg from "./img/partners/kaspersky.png"
import qsanImg from "./img/partners/qsan.png"
import quantaImg from "./img/partners/quanta.png"
import riverbedImg from "./img/partners/riverbed.png"
import supermicroImg from "./img/partners/supermicro.png"
import hitachiImg from "./img/partners/hitachi.png"

const partnerImages = [
  seagateImg,
  ibmImg,
  kasperskyImg,
  qsanImg,
  quantaImg,
  riverbedImg,
  supermicroImg,
  hitachiImg
]

export default partnerImages