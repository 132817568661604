import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'Ubuntu', sans-serif;

    @media (min-width: ${({theme}) => theme.breakpoints.xxl}) {
      font-size: 18px;
    }

    scroll-behavior: smooth;
  }


  a {
    color: inherit;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  h1 {
    font-size: 2rem;
  
    @media (min-width: ${({theme}) => theme.breakpoints.xxl}) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 1.75rem;
    @media (min-width: ${({theme}) => theme.breakpoints.xxl}) {
      font-size: 2.25rem;
    }
  }

  p {
    line-height: 1.5;
    font-weight: 300;
    letter-spacing: .5px;
  }

  li, ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }
`;
