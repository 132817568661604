// theming
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
// global style
import { GlobalStyle } from "./GlobalStyle";
// dependencies
import FloatingWhatsApp from "react-floating-whatsapp";
// components
import Hero from "./components/Hero";
import Featured from "./components/Featured";
import About from "./components/About";
import Service from "./components/Service";
import Value from "./components/Value";
import Partner from "./components/Partner";
import Footer from "./components/Footer";
// assets
import whatsappProfilePicture from "./img/whatsapp_profile_picture.png"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Hero />
      <Featured />
      <About />
      <Service />
      <Value />
      <Partner />
      <Footer />

      <FloatingWhatsApp 
        phoneNumber="+62 878-8885-9888"
        accountName="Hery"
        avatar={whatsappProfilePicture}
        allowClickAway={true}
        chatMessage="Halo, apakah ada yang bisa dibantu?"
      />
    </ThemeProvider>
  );
}

export default App;
