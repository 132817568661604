import styled from "styled-components";
// components
import Container from "./Container";
// assets
import bgImg from "../img/about-bg.jpg";

const StyledInnerContent = styled.div`
  width: 100%;

  p {
    padding: 0 1rem;
  }

  p:nth-child(2) {
    border-left: 0.25rem solid ${({ theme }) => theme.colors.primary};
  }

  p:nth-child(3) {
    text-align: right;
    border-right: 0.25rem solid ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }
`;

const StyledAboutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  img {
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;

    img {
      width: 50%;
    }
  }
`;

const StyledAbout = styled.section`
  padding: 4em 2em;
`;

const About = () => {
  return (
    <StyledAbout>
      <Container>
        <StyledAboutContent>
          <StyledInnerContent>
            <h2>Over the Past Years</h2>
            <p>
              We have positioned ourselves as a total solution provider for
              enterprise server and network storage infrastructure in Indonesia.
              We have achieved numerous successes in implementation projects for
              MNCs and government institutions. These successes can only be
              achieved through dedication, innovation, and a high level of
              technical competence. This perseverance to succeed will help us
              serve you better today and in the coming years.
            </p>
            <p>
              Working closely with leading global names in the industry, TMI is
              well positioned to provide the best result-oriented approach to
              meet clients needs. We listen to our client's problems and deliver
              the most effective solution. This commitment has enabled us to
              build trust and forge long-term relationships with our clients.
            </p>
          </StyledInnerContent>
          <img src={bgImg} alt="" />
        </StyledAboutContent>
      </Container>
    </StyledAbout>
  );
};

export default About;
