import styled from "styled-components";
// components
import Container from "./Container";
// assets
import partnerImages from "../partnerImages"

const StyledImgContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  align-items: center;
  justify-items: center;
  
  padding: 1em;

  background-color: white;

  border-radius: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`

const StyledInnerContent = styled.div`
  padding: 2em;

  background-color: ${({theme}) => theme.colors.gray};

  border-radius: 10px;

  text-align: center;
`;

const StyledPartner = styled.section`
  padding: 4em 2em;
`;

const Partner = () => {
  return (
    <StyledPartner>
      <Container>
        <StyledInnerContent>
          <h2>Trusted Partners</h2>
          <p>We are supported by well-known vendors</p>
          <StyledImgContainer>
          {
            partnerImages.map((image, idx) => (
              <img key={idx} src={image} alt="" />
            ))
          }
        </StyledImgContainer>
        </StyledInnerContent>
      
      </Container>
    </StyledPartner>
  );
};

export default Partner;
