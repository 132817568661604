import styled from "styled-components";
// components
import Container from "./Container";
// assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMobile } from "@fortawesome/free-solid-svg-icons";

const StyledCopyright = styled.div`
  border-top: 2px solid ${({theme}) => theme.colors.gray};
  padding: 4px;
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    gap: 4em;
  }
`;

const StyledFooter = styled.footer`
  padding: 2em 1em;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <StyledInnerContainer>
          <div>
            <h3>Address</h3>
            <p>
              Jalan Daan Mogot No. 6 AO <br />
              Kel. Wijaya Kuruma Kec. Grogol <br />
              Petamburan, Jakarta Barat 11460
            </p>
          </div>
          <div>
            <h3>Contact Us</h3>
            <p>
              <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
              <span>sales@tmi.co.id</span>
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} size="lg" />{" "}
              <span>(021) 566-7821</span>
            </p>
            <p>
              <FontAwesomeIcon icon={faMobile} size="lg" />{" "}
              <span>569-676-1819</span>
            </p>
          </div>
        </StyledInnerContainer>
        <StyledCopyright>
          &copy; 2022 Theodorus Andi 
        </StyledCopyright>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
