import standardCharteredImg from "./img/clients/standard_chartered.png";
import plnImg from "./img/clients/pln.png";
import kpuprImg from "./img/clients/kpupr.svg";
import bnp2tkiImg from "./img/clients/bnp2tki.png";
import bpkpImg from "./img/clients/bpkp.png";
import bmkgImg from "./img/clients/bmkg.png";
import sinarmasImg from "./img/clients/sinarmas.webp";
import interindoImg from "./img/clients/interindo.svg";
import jatelindoImg from "./img/clients/jatelindo.jpg";
import indoPratamaImg from "./img/clients/indo_pratama.png";
import fifgroupImg from "./img/clients/fifgroup.png";
import jpImg from "./img/clients/jasaraharja_putera.webp";
import mitraKeluargaImg from "./img/clients/rs_mitra_keluarga.jpg";
import indonetImg from "./img/clients/indonet.png";
import ptkaImg from "./img/clients/ptka.jpg";
import mustikaImg from "./img/clients/mustika_citra_rasa.jpg";
import iconImg from "./img/clients/indonesia_connect.png";
import paninImg from "./img/clients/panin.gif";
import intraImg from "./img/clients/intra_abadi.jpg";
import ensevalImg from "./img/clients/enseval.png";

const clientImages = [
  standardCharteredImg,
  plnImg,
  kpuprImg,
  bnp2tkiImg,
  bpkpImg,
  bmkgImg,
  sinarmasImg,
  interindoImg,
  jatelindoImg,
  indoPratamaImg,
  fifgroupImg,
  jpImg,
  mitraKeluargaImg,
  indonetImg,
  ptkaImg,
  mustikaImg,
  iconImg,
  paninImg,
  intraImg,
  ensevalImg,
];

export default clientImages
