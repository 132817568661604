import styled from "styled-components";
// assets
import logo from "../img/logo.png";

const StyledNavbar = styled.nav`
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 10;

  height: 4rem;

  img {
    height: 100%;
    width: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
     left: unset;
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <img src={logo} alt="logo" />
    </StyledNavbar>
  );
};

export default Navbar;
