export const theme = {
  colors: {
    primary: "#f05b2c",
    secondary: "#283891",
    lightGray: "#f8f7fc",
    gray: "#ced2da"
  },
  breakpoints : {
    xs: 0,
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px"
  }
};
