import styled from "styled-components";
// assets
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import clientImages from "../clientImages";
// components
import Slider from "react-slick";
import Container from "./Container";

import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const StyledNextArrow = styled.button``;

const NextArrow = ({ className, style, onClick }) => {
  return (
    <StyledNextArrow
      style={{ ...style, display: "block" }}
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCircleChevronLeft} />
    </StyledNextArrow>
  );
};

const StyledPrevArrow = styled.button``;

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <StyledPrevArrow
      style={{ ...style, display: "block" }}
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCircleChevronRight} />
    </StyledPrevArrow>
  );
};

const StyledFeaturedContent = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;

  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  p {
    padding-right: 0;
    font-weight: 600;
    font-size: 1.25rem;
  }

  img {
    max-height: 100px;
    margin: 0 auto;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
    color: black;
  }

  .slick-slide {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 20% 80%;
    padding-bottom: 0;
    overflow-x: visible;

    .slick-prev:before,
    .slick-next:before {
      display: block;
      color: black;
    }
    
    p {
      padding-right: 40px;
    }
  }
`;

const StyledFeatured = styled.section`
  padding: 1em 0.5em;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 2em 1em;
    text-align: left;
  }
`;

const Featured = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true ,
    speed: 4000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    swipeToSlide: false,     
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay: false,
          swipeToSlide: true,
          speed: 500,
          autoplaySpeed: 3000,
          pauseOnHover: false,
        }
      },
    ]  };


  return (
    <StyledFeatured id="featured">
      <Container>
        <StyledFeaturedContent>
          <p>Trusted by companies all over Indonesia</p>

          <Slider {...settings}>
            {clientImages.map((image, idx) => (
              <div key={idx}>
                <img src={image} alt="" />
              </div>
            ))}
          </Slider>
        </StyledFeaturedContent>
      </Container>
    </StyledFeatured>
  );
};

export default Featured;
