import styled from "styled-components";
// components
import Container from "./Container";
// assets
import valueImg1 from "../img/value-1.jpg";
import valueImg2 from "../img/value-2.jpg";

const StyledTopRow = styled.div`
  p {
    border-left: 0.25rem solid ${({ theme }) => theme.colors.primary};
  }
`;

const StyledBottomRow = styled.div`
  text-align: right;
  p {
    border-right: 0.25rem solid ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledInnerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 2em;

  img {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  p {
    padding: 0 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledValue = styled.section`
  padding: 4em 2em;
`;

const Value = () => {
  return (
    <StyledValue>
      <Container>
        <StyledInnerContent>
          <img src={valueImg1} alt="" />
          <StyledTopRow>
            <h2>System Integrator</h2>
            <p>
              Aligning cheaper, pre-configured components and off-the-shelf
              software to meet key business goals, including designing a
              customized architecture or application.
            </p>
          </StyledTopRow>
          <StyledBottomRow>
            <h2>Commitment</h2>
            <p>
              We believe that the most important factor to success is to build a
              long term relationship with our customers. Each customer has its
              own unique requirements.
            </p>
          </StyledBottomRow>
          <img src={valueImg2} alt="" />
        </StyledInnerContent>
      </Container>
    </StyledValue>
  );
};

export default Value;
