import styled from "styled-components";
import Container from "./Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck, faUserTie, faServer } from '@fortawesome/free-solid-svg-icons'

const StyledCard = styled.div`
  padding: 2em .5em;
  
  border-radius: 10px;
  
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;

  text-align: center;


  background-color: ${({theme})=>theme.colors.lightGray};

  svg {
    color: ${({theme})=>theme.colors.secondary};
  }

  span {
    font-weight: 300;
    font-size: 1rem;
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 33.3333%;
  }
`;

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 75%;
    flex-direction: row;
  }
`;

const StyledInnerContent = styled.div`
  width: 100%;

  text-align: center;

  p {
    font-weight: 300;
    line-height: 1.5;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }
`;

const StyledServiceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledService = styled.section`
  padding: 4em 2em;
`;

const Service = () => {
  return (
    <StyledService>
      <Container>
        <StyledServiceContent>
          <StyledInnerContent>
            <h2>Service & Support</h2>
            <p>
              We provide a full spectrum of resources to assist our customers in
              implementing the latest technology as well as maintaining the IT
              infrastructure to ensure business operation continuity.
            </p>
          </StyledInnerContent>
          <StyledCardContainer>
            <StyledCard>
            <FontAwesomeIcon icon={faListCheck} size="2x" />
              <h3>Project Management</h3>
            </StyledCard>
            <StyledCard>
            <FontAwesomeIcon icon={faUserTie} size="2x" />
              <h3>Consulting</h3>
            </StyledCard>
            <StyledCard>
            <FontAwesomeIcon icon={faServer} size="2x" />
              <h3>Installation Service </h3>
              {/* <span>server, rack & network storage</span> */}
            </StyledCard>
          </StyledCardContainer>
        </StyledServiceContent>
      </Container>
    </StyledService>
  );
};

export default Service;
